h1 {
  font-size: 3rem;
  font-weight: 700;
}

p,
li {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 30px;
}

.header {
  padding: 10px 20px 8px 23px;
  position: sticky;
  top: 0;
  z-index: 555;
  background: #fff;
  width: 100%;
  transition: top 0.3s ease;
}
.header.scrolling {
  top: -100px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo img {
  height: 60px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
}
.phone {
  font-size: 1.5rem;
  margin-right: 7px;
}
.phone-num {
  font-size: 1.25rem;
  color: black;
  text-decoration: none;
}
.search {
  font-size: 1.75rem;
  margin-left: 20px;
  margin-right: 10px;
}
.hamburger {
  display: block;
  font-size: 2.19rem;
}
.reuseable-banner {
  height: 50vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: unset;
  color: #fff;
  padding: 0px 30px 0 22px;
}
.reuseable-container {
  max-width: 144rem;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 150px;
}

.reuseable-banner-text {
  font-size: 3rem;
  font-weight: 700;
}
.retail-price-banner {
  background-position: 35% !important;
}
/* ======MENU SECTION ======== */
.show-menu {
  display: block;
  background-image: url(../src/assets/home/menu.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 24px;
  padding-right: 20px;
}

.show-menu li {
  list-style: none;
  /* padding: 10px; */
  color: #fff;
  font-size: 2.4rem;
  font-weight: 600;
  text-decoration: none;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.close-btn {
  display: flex;
  align-self: flex-end;
  padding-bottom: 10px;
}

.hide-menu {
  display: none;
}

.desktop-nav {
  display: none;
}

/* ===========HERO SECTION=========================== */

.banner-container {
  display: none;
}
/* .header-container{
  
 } */

.hero-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0px 20px 30px 23px;
  width: 100%;
  height: 486px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./assets/home/bannerImage.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-text h1 {
  max-width: 330px;
  font-size: 4rem;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 1px;
  color: white;
}
.hero-text h4 {
  color: whitesmoke;
  font-size: 2.4rem;
  font-weight: 600;
  padding-bottom: 22px;
  line-height: normal;
  letter-spacing: 0.64px;
  white-space: nowrap;
}
.hero-line {
  margin-bottom: 30px;
  width: 285px;
  height: 4px;
  background: #a1151a;
}

.hero-container a {
  text-decoration: none;
}
.hero-button:hover {
  opacity: 0.9;
  transform: scale(1.02);
}

.hero-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  width: 174px;
  height: 50px;
  padding: 7px 0px 7px 25px;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: normal;
  border-radius: 15px;
  border: none;
  background: #a1151a;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  transition: 0.3s ease-in-out;
}

.icon {
  margin-left: 20px;
  margin-right: 5px;
}

/* ===========OVERVIEW SECTION=========================== */
.overview {
  margin-bottom: 2rem;
}

.overview-header {
  line-height: 150%;
  font-size: 2rem;
  font-weight: 400;
  color: #a1151a;
  padding: 3.8rem 3.5rem 2rem 2.4rem;
}

.overview-text {
  padding: 0rem 2.4rem;
}

/* ===========MIDVIEW SECTION=========================== */
.mid-view {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 144rem;
  height: 100%;
  margin: 0 auto;
}

.mid-view-text {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
  background-color: #f8f8f8;
  text-align: center;
}

.mid-view span {
  color: #a1151a;
  font-size: 3.6rem;
}

.mid-view h3 {
  color: #262626;
  font-size: 2rem;
  line-height: 150%;
  /* max-width: 263px; */
  font-weight: 500;
  padding: 30px 24px;
}

.mid-view img {
  width: 100%;
}

/* ===========WHAT WE DO HOME SECTION=========================== */
.home-what-we-do {
  padding: 50px 20px;
  max-width: 144rem;
  margin: 0 auto;
}

.home-what-we-do h1 {
  color: black;
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 20px 0px;
  text-align: center;
}

.home-what-we-do hr {
  border: 4px #a1151a solid;
  width: 189px;
  text-align: center;
  margin: 0 auto;
}

.home-what-we-do p {
  max-width: 510px;
  color: #000;
  font-size: 2rem;
  line-height: 150%;
  font-weight: 700;
  letter-spacing: 1px;
  padding-top: 27px;
  text-align: center;
  margin: 0 auto;
}

.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.section-a {
  background-image: url(../src/assets/home/startUp.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 295px;
  border-radius: 4px;
}

.section-b {
  background-image: url(../src/assets/services/consultancyHero.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 295px;
  border-radius: 4px;
}

.section-c {
  background-image: url(../src/assets/home/businesses.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 295px;
  border-radius: 4px;
}

.section-d {
  background-image: url(../src/assets/home/taxation.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 295px;
  border-radius: 4px;
}

.section-text {
  color: #fff;
  font-size: 2.6rem;
  font-weight: 800;
  padding-bottom: 3rem;
  letter-spacing: 2px;
}

/* ===========RESOURCES SECTION=========================== */
.count-on-us {
  padding: 38px 0;
  /* max-width: 144rem;
    margin: 0 auto; */
}

.count-on-us h3 {
  color: #a1151a;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  padding: 30px 10px;
}

.resources {
  padding: 30px 24px;
  max-width: 144rem;
  margin: 0 auto;
}

.resources img {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 219px;
}

.resources h3 {
  font-size: 2rem;
  font-weight: 700;
  padding-top: 52px;
  padding-bottom: 10px;
  line-height: 30px;
  letter-spacing: 1px;
}

.resources p {
  font-size: 1.6rem;
  /* max-width: 600px; */
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1px;
}

.resource-link {
  color: #a1151a;
  font-size: 1.4rem;
  font-weight: 600 !important;
  padding-top: 20px;
  line-height: 30px;
  letter-spacing: 1px;
}
.fact-flip {
  display: flex;
  flex-direction: column-reverse;
}
.resources a {
  text-decoration: none;
}

/*================WHAT WE DO ABOUT US SECTION==================*/

.what-we-do-text {
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
}

.what-we-do {
  background: url(../src/assets/aboutus/sunray.png) no-repeat center;
  background-size: cover;
  padding: 5rem 0;
  margin-bottom: 2rem;
}

/* ============ ABOUT US ============ */
.about-hero {
  background-image: url(../src/assets/aboutus/aboutHeroImg.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem 2.4rem;
}

.about-hero h1 {
  color: white;
}

.about-li {
  padding: 0 2rem 1rem 5rem;
}

.mission-overlap-container .about-li {
  padding-left: 3rem;
}

#mission-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
  max-width: 144rem;
  margin-left: auto;
  margin-right: auto;
}
.mission-image-seg1 {
  background: url(../src/assets/aboutus/buildings.webp) no-repeat;
  width: 100%;
  height: 259px;
  background-size: cover;
  background-position: center;
}
.mission-image-seg2 {
  background: url(../src/assets/aboutus/manInField.webp) no-repeat;
  width: 100%;
  height: 259px;
  background-size: cover;
  background-position: center;
}
.mission-image-seg3 {
  background: url(../src/assets/aboutus/paperwork.webp) no-repeat;
  width: 100%;
  height: 259px;
  background-size: cover;
  background-position: center;
}

.mission-overlap-container {
  background: #fff;
  margin-top: calc(1vw - 120px);
  margin-bottom: 50px;
  width: calc(100vw - 80px);
  border: 2px solid #a1151a;
  border-radius: 10px;
  padding: 20px;
}
.mission-overlap-container h2 {
  color: #a1151a;
  padding-bottom: 2rem;
}

.founder-container {
  padding: 5rem 2rem 9rem;
}
.founder-container h2 {
  text-align: center;
  color: #a1151a;
  padding-bottom: 3rem;
}

.founder-context h6 {
  font-size: 2rem;
  font-weight: 600;
  color: #a1151a;
  padding-bottom: 2rem;
}
.founder-content img {
  max-width: 319px;
}
.founder-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

/* ===========TESTIMONIAL SECTION=========================== */
  .client-container{
    margin-bottom: 100px;
    width: 100%;
    max-width: 144rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem 9rem;
  }

.slick-list {
  margin: 0 auto;
}

.client-text {
  color: #a1151a;
  text-align: center;
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 30px;
}

.client-card {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  padding: 10px 20px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.25);
  max-width: 490px;
}
.client-card img {
  max-width: 250px;
}
.client-card p {
  color: black;
  font-size: 2rem;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.4px;
  margin: 35px 25px;
}

.client-subtext {
  color: #000;
  text-align: center;
  font-size: 20px;
  padding: 38px 27px 26px 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1px;
}

.slider-button {
  visibility: hidden;
}

.client-img {
  display: flex;
  margin: 0 auto;
  padding: 20px 0;
}

/* ============ RECOMMENDATIONS SECTION ============ */
.recommendation-container ::-webkit-scrollbar {
  width: 20px; 
}
.recommendation-container ::-webkit-scrollbar-thumb {
  background:#A1151A; 
  border-radius: 5px; 
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(120, 116, 116, 0.19); 
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-track:hover {
  background: #eaeaea; 
}
.recommend-hero {
  background-image: url(../src/assets/recommendHero.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 12rem 2.4rem;
  margin-bottom: 5rem;
}
.recommend-hero h1 {
  color: white;
}

.recommend-text {
  color: #a1151a;
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.8px;
  margin-bottom: 3.2rem;
    padding: 0rem 2rem;
}
.recommendations {
    padding: 0 0rem 5rem 2rem;
}
 .bottom-svg{
     display: none;
  }
  .redfox-text h1{
    display: none;
  }
.recommendations-card-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 675px;;
  margin: auto;
  padding: 20px 0;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 700px;
  border: 1px solid transparent;
  border-radius: 8px;
}
.recommend-card {
  display: flex;
  padding: 20px;
  width: 100%;
  max-width: 550px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 5rem;
  gap: 20px;
  border-radius: 28.14px;
  background: #f8f8f8;
  box-shadow: 0px 14.07px 23.45px 0px rgba(0, 0, 0, 0.3);
}
.recommend-desc {
  color: #232323;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 150%; /* 36px */
  letter-spacing: 0.48px;
}
.recommend-name {
  color: #000;
  font-size: 1.6rem;
  font-weight: 700;
}
.recommend-comp {
  color: #787474;
  font-size: 1.6rem;
  font-weight: 500;
}
.svg-background {
  display: none;
}

/* ============ SERVICES ============ */

.service-hero {
  background-image: url(../src/assets/services/heroSection.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem 2.4rem;
}

.service-hero h1 {
  color: white;
  max-width: 144rem;
  margin: 0 auto;
}

.service-banner h2 {
  font-size: 2.5rem;
  font-weight: 800;
  color: white;
  text-align: center;
}

/* ============ SUPPORT SERVICES SECTION ============ */
.support-service-hero {
  background-image: url(../src/assets/services/supportServiceHeader.webp);
  /* background-position: center; */
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem 2.4rem;
}

.support-service-hero h1 {
  color: white;
  max-width: 144rem;
  margin: 0 auto;
}

.support-overview-header {
  color: #a1151a;
  font-size: 3rem;
  font-weight: 500;
  padding: 3.8rem 2.4rem 2rem 2.4rem;
}

.support-overview-text {
  padding: 0rem 2.4rem;
}

.support-header {
  letter-spacing: 0.96px;
  text-align: left;
  line-height: 150%;
  font-size: 2rem;
  padding: 3.8rem 3.5rem 3rem 2.4rem;
}

.font-weight {
  font-weight: 600 !important;
}

.rich-text {
  color: #a1151a;
  font-weight: 400;
  line-height: 150%;
  font-size: 2rem;
}

/* ============ SERVICES CARD SECTION ============ */

.service-banner {
  background-image: url(../src/assets/services/laptop.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 54px 0;
}

.service-banner h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  letter-spacing: 4px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fff;
}
.service-writeup-wrapper {
  padding: 3rem 0;
}

.service-card-wrapper {
  padding: 0 2.5rem 5rem;
}
.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.service-card {
  border-radius: 8px;
  border: 2px solid transparent;
  box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 387px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.service-image {
  width: 100%;
}
.service-card-content {
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.service-card-title {
  color: #a1151a;
  font-size: 2rem;
  font-weight: 700;
}
.service-card-description {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 150%;
}
.service-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.service-card a {
  transition: 0.3s ease-in-out;
}
.service-card a:hover {
  transform: scale(1.03);
}
.service-btn span {
  padding: 1rem;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 0px 0px 4px;
  background: #d9d9d9;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 1px;
}
.service-icon {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
  background: #a1151a;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
}
/* ============ EXPLORE SECTION ============ */
.require-info-container {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.explore-image-seg1 {
  width: 100%;
  height: 259px;
  background: url(../src/assets/services/financeGraphOnPhone.png) no-repeat
    center;
  background-size: cover;
}

.explore-overlap-container {
  background: #f8f8f8;
  margin-top: calc(1vw - 40px);
  margin-bottom: 20px;
  width: calc(100vw - 80px);
  padding: 20px;
  max-width: 144rem;
  /* margin:0 auto; */
}
.explore-overlap-container h2 {
  color: #a1151a;
  font-size: 2rem;
  line-height: 150%;
  padding-bottom: 2rem;
}

.explore {
  padding: 3rem 2.4rem 3rem;
}

.explore-header {
  color: #a1151a;
  font-size: 2.4rem;
  font-weight: 700;
}

.explore-section-container {
  padding: 3rem 22px 10rem;
  max-width: 144rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.explore-section-container a {
  width: 100%;
}

.explore-section-a {
  background-image: url(../src/assets/explore/business.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 275px;
}

.explore-section-b {
  background-image: url(../src/assets/explore/individuals.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 275px;
  border-radius: 4px;
}

.explore-section-c {
  background-image: url(../src/assets/explore/taxation1.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  /* max-width: 800px; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 275px;
}
.explore-section-d {
  background-image: url(../src/assets/explore/taxation.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 275px;
}

.explore-section-e {
  background-image: url(../src/assets/explore/taxation2.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 275px;
  border-radius: 4px;
}

.explore-section-text {
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  line-height: 120%;
  padding-bottom: 3rem;
}

/* ======== INVESTMENT PAGE ======= */
.invest-hero {
  background-image: url(../src/assets/services/investmentHeader.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem 2.4rem;
}

.invest-hero h1 {
  color: white;
  max-width: 144rem;
  margin: 0 auto;
}

.invest-li {
  padding-left: 6rem;
  padding-bottom: 10px;
}

/* ======== BUSINESS START UP PAGE ======= */
.business-hero {
  background-image: url(../src/assets/services/startUpHeader.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem 2.4rem;
}

.business-hero h1 {
  color: white;
  line-height: 28px;
  max-width: 144rem;
  margin: 0 auto;
}
.business-start-line {
  margin: 5rem 0;
}

.business-start-text {
  color: #a1151a;
  line-height: 150%;
  font-size: 2rem;
}

/* ======== COMPLIANCE SERVICE PAGE ======= */
.compliance-hero {
  background-image: url(../src/assets/services/complianceHeader.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem 2.4rem;
}

.compliance-hero h1 {
  color: white;
  max-width: 144rem;
  margin: 0 auto;
}
.compliance-service-text {
  font-weight: 700;
  padding-top: 2rem;
}

/* ======== SERVICE PAGE ========== */
.service-page-h2 {
  color: #a11511;
  padding: 0 2.4rem 0rem 2.4rem;
  font-weight: 500;
}
.service-page-h3 {
  padding: 2rem 2.4rem;
  font-size: 2rem;
}
.service-page-h4 {
  padding: 0rem 0rem 1.5rem 2.4rem;
  font-size: 2rem;
}

/* ======== CONSULTANCY PAGE ========== */
.consult-hero {
  background: url(./assets/services/consultancyHero.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem 2.4rem;
}
.consult-hero h1 {
  color: white;
  margin-bottom: 2.4rem;
  max-width: 144rem;
  margin: 0 auto;
}

.consultancy img {
  max-width: 100%;
  height: auto;
  padding-bottom: 1.5rem;
}

/* ======== TAXATION PAGE ========== */
.taxation-hero {
  background: url(./assets/services/taxationHero.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem 2.4rem;
}
.taxation-hero h1 {
  color: white;
  margin-bottom: 2.4rem;
  max-width: 144rem;
  margin: 0 auto;
}
.inherit {
  align-items: inherit !important;
}
.text-center {
  text-align: center !important ;
}
.taxation img {
  width: 100%;
  padding-bottom: 2rem;
}
.tax-service-heading {
  text-align: center;
  padding-bottom: 1.5rem;
  font-size: 1.6rem;
}
.taxation-image-seg1 {
  background: url(../src/assets/services/taxSelf.png) no-repeat;
  width: 100%;
  height: 494px;
  background-size: cover;
  background-position: center;
}
.taxation-image-seg2 {
  background: url(../src/assets/services/taxPlanning.png) no-repeat;
  width: 100%;
  height: 426px;
  background-size: cover;
  background-position: center;
}
.taxation-image-seg3 {
  background: url(../src/assets/services/taxVat.png) no-repeat;
  width: 100%;
  height: 487px;
  background-size: cover;
  background-position: inherit;
}
.taxation-image-seg4 {
  background: url(../src/assets/services/taxHrmc.png) no-repeat;
  width: 100%;
  height: 363px;
  background-size: cover;
  background-position: center;
}
.taxation-image-seg5 {
  background: url(../src/assets/services/taxProtection.png) no-repeat;
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
}
.taxation-overlap-container {
  background: #f8f8f8;
  margin-top: calc(1vw - 70px);
  width: calc(100vw - 80px);
  border-radius: 10px;
  padding: 3rem 0;
  margin-left: auto;
  margin-right: auto;
}
.tax-modifier {
  background: #f8f8f8;
  margin-top: calc(1vw - 70px);
  width: calc(100vw - 80px);
  border-radius: 10px;
  padding: 3rem 0;
  margin-left: auto;
  margin-right: auto;
}

/* =================FACTSHEETS========================= */
.factsheet-hero {
  background-image: url(../src/assets/home/factsheetHero.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem 2.4rem;
}

.factsheet-hero h1 {
  color: white;
  margin: 0 auto;
  max-width: 144rem;
}
.factsheet-cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 2.4rem 5rem;
  gap: 41px;
  margin: 0 auto;
  max-width: 144rem;
}

/* =================RESOURCES========================= */
.resource-hero {
  background-image: url(../src/assets/home/resourcesHeader.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem 2.4rem;
}

.resource-hero h1 {
  color: white;
  margin: 0 auto;
  max-width: 144rem;
}

.resource-text {
  padding: 3rem 2.4rem;
  font-weight: 400;
  line-height: 150%;
}

.resource-card {
  display: flex;
  max-width: 360px;
  margin-bottom: 3rem;
  border-radius: 15px;
  background: #f8f8f8;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 444px;
  padding: 60px 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  /* margin-left: 3rem; */
  /* gap: 3rem; */
}

.resource-card h3 {
  color: #a1151a;
  font-size: 3rem;
  font-weight: 500;
}
.resource-card p {
  text-align: center;
}
.resource-button {
  border-radius: 50px;
  padding: 10px 8px 10px 25px;
}

.resource-overview-text {
  padding: 2rem 1rem 2rem 2.4rem;
}
.resource-identifier {
  color: #a11511;
  padding-top: 2rem;
  padding-left: 2.4rem;
  font-size: 1.6rem;
  max-width: 144rem;
  margin: 0 auto;
  font-weight: 600;
}
.learnmore-button:hover {
  opacity: 0.9;
  transform: scale(1.02);
}

.learnmore-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  cursor: pointer;
  padding: 7px 0px 7px 36px;
  font-size: 1.6rem;
  font-weight: 600;
  border-radius: 15px;
  border: none;
  background: #a1151a;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
}

.accsend-overview {
  color: #a1151a;
  font-size: 2.4rem;
  padding: 6rem 0rem 2rem 2.4rem;
  font-weight: 500;
}

/* ======== CUSTOM INPUTS ========== */
.custom-input {
  margin: 0rem 2rem 2.5rem 2rem;
}

.top-input {
  max-width: 100vw;
  height: 50px;
  border: 1px solid #d9d9d9;
  background: rgba(217, 217, 217, 0.5);
  color: #a1151a;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 13px 0 13px 10px;
}

.bottom-input {
  border: 1px solid #d9d9d9;
  max-width: 100vw;
  padding: 13px 0 13px 10px;
  font-size: 1.6rem;
  font-weight: 400;
}

.personal-text {
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3rem;
  letter-spacing: 0.04rem;
  padding: 3rem 0rem 0rem 6.06rem;
}

/* ===========ACCSEND======================== */

.resource-accsend-text {
  padding: 5rem 1rem 5rem 2.4rem;
}
.accsend-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accsend-overview {
  max-width: 497px;
}

.accsend-button {
  padding: 20px;
  margin-left: 2.4rem;
  margin-bottom: 5rem;
}
.accsend-img {
  padding-left: 2.4rem;
  margin-bottom: 5rem;
}

.accsend-img img {
  margin-right: 20px;
}

/* ======== USEFUL LINK INPUTS ========== */
.ul-top-input {
  max-width: 100vw;
  height: 50px;
  border: 0.5px solid rgba(161, 21, 26, 0.39);
  color: #a1151a;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 20px 10px 40px 10px;
}

.ul-bottom-input {
  border: 1px solid rgba(161, 21, 26, 0.39);
  max-width: 100vw;
  padding: 25px 10px;
  font-size: 1.6rem;
  font-weight: 400;
  background: #f5e7e7;
}
.useful-link-list {
  color: #a11511;
  padding-left: 4rem;
}

/* ======== TAX CALENDAR INPUTS ========== */
.resource-spanned-text-red {
  color: #a11511;
}
.resource-spanned-text-bold {
  font-weight: bolder;
}
.tax-calendar-main {
  margin-bottom: 3rem;
}
.tax-cal-input {
  margin: 2rem 1rem 2rem 2.4rem;
}
.taxcal-month-div {
  max-width: 100vw;
  height: 50px;
  border: 0.5px solid rgba(161, 21, 26, 0.39);
  color: #fff;
  background: #a1151a;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1.9rem 1rem;
  margin-bottom: 2rem;
}
.taxcal-table-container {
  background: #f5f5f5;
  border: 1px solid rgba(161, 21, 26, 0.39);
  padding: 1.8rem 0.5rem 0rem 0.5rem;
  margin: 0 1rem 2rem 2.4rem;
}
/*=============== light red text ===============*/
.light-red-text {
  color: #a11511;
  margin-bottom: 1.5rem;
}
.taxcal-date-text {
  display: flex;
  justify-content: space-between;
}
.light-red-text p {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
}
/*=============== bold red text ===============*/
.bold-red-text {
  color: #a11511;
  margin-bottom: 1.5rem;
}
.bold-red-text p {
  font-size: 1.2rem;
  font-weight: bolder;
  line-height: 1.5;
}
/* =============== bold black text ===============*/
.bold-text {
  margin-bottom: 1.5rem;
}
.bold-text p {
  font-size: 1.2rem;
  font-weight: bolder;
  line-height: 1.5;
}

/* ======== CONTACT US ========== */
.contact-hero {
  background-image: url(../src/assets/contactus/heroSection.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem 2.4rem;
}

.contact-hero h1 {
  color: white;
  max-width: 144rem;
  margin: 0 auto;
}

.contact-info {
  color: #202020;
  padding: 20px 24px 50px;
}

.location {
  display: flex;
  align-items: center;
  padding-top: 30px;
}

.contact-text {
  color: #202020;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 16px;
}

.contact-email {
  color: #202020;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 1px;
  padding-left: 16px;
}

.question-text {
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1px;
  padding: 54px 0;
}

.contact-form {
  padding-bottom: 30px;
  padding-top: 20px;
  margin-bottom: 30px;
  max-width: 144rem;
  margin: 0 auto;
}

.contact-form h1 {
  color: #a1151a;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 25px;
}

.form-label {
  color: #000;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 1px;
  padding-top: 20px;
  padding-bottom: 30px;
}

.form {
  padding: 0 25px;
}

input {
  width: 100%;
  height: 60px;
  border-radius: 4px;
  border: 1px solid rgba(38, 38, 38, 0.3);
}

textarea {
  height: 206px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(38, 38, 38, 0.3);
}

input::placeholder {
  padding-left: 9px;
}

textarea::placeholder {
  padding-left: 9px;
  padding-top: 9px;
  font-size: 1.8rem;
}

.contact-btn {
  background: #a1151a;
  color: #fff;
  display: flex;
  margin: 30px auto;
  border-radius: 8px;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 25px;
  padding: 11px 57px;
  border: none;
}

.success-container {
  letter-spacing: 2px;
  line-height: 3rem;
  margin: 50px 0;
  text-align: center;
}

.success-text {
  padding-top: 2rem;
}

.success-page-link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.success-page-link p {
  padding-left: 1rem;
}

.map-outer {
  padding: 0;
}
.map_canvas {
  background: white;
  width: 100%;
  height: 70vh;
}
.map_iframe {
  max-width: 100%;
  height: 70vh;
  border: 0;
}

/* ============ FAQS =========================== */

.faqs-container {
  padding: 82px 24px 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faqs-container h1 {
  color: #302d2d;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin-bottom: 30px;
}
.faq-btn {
  display: none;
}

.mobile-btn {
  margin-top: 1rem;
  display: inline-block !important;
}
.transparent-link {
  text-decoration: none;
  border-radius: 15px;
  border: 1px solid #a1151a;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 5px 20px;
  background-color: transparent;
  transition: all 0.3s ease;
  color: #a1151a;
  font-size: 1.6rem;
  font-weight: 700;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  height: 60px;

  &:hover {
    background-color: #a1151a;
    color: white;
  }
}
.accordion-wrapper {
  width: 100%;
}
.accordion-item {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background: #fff;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  padding: 20px;
  min-height: 100px;
}
.accordion-que {
  display: flex;
  gap: 30px;
  align-items: center;
  cursor: pointer;
}
.accordion-que h2 {
  font-size: 2rem;
}
.accordion-ans {
  display: none;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}
.accordion-ans .show {
  display: block;
  height: auto;
  max-height: 100%;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}
.show p {
  margin-top: 30px;
}

/* ============ GET IN TOUCH ============ */
.get-in-touch {
  background-image: url(../src/assets/home/banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  padding-bottom: 30px;
}

.get-in-touch-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.get-in-touch-header {
  color: #fff;
  padding: 30px 0;
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
}

.get-in-touch-button {
  padding: 9px 65px 12px 66px;
  color: white;
  font-size: 1.8rem;
  background-color: #a1151a;
  font-weight: 600;
  border: 3px solid #fff;
  border-radius: 15px;
}

/* ===========RetailPriceTable================ */
.retail-price-wrapper {
  padding: 20px 4% 50px;
}
.retail-price-container {
  max-width: 144rem;
  margin: 0 auto;
}
.retail-price-container p {
  color: #a1151a;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 10px;
}
.data-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 8px;
}

.data-table th,
.data-table td {
  border: 1px solid rgba(217, 217, 217, 0.5);
  padding: 4px;
  text-align: center;
}

.data-table th {
  border: 1px solid #fff;
  background: #b8171d;
  color: #fff;
  font-weight: 700;
}
.retail-price-table .data-table tbody td:first-child {
  border: 1px solid #fff;
  background: #b8171d;
  color: #fff;
  font-weight: 700;
}
.data-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.data-table tbody tr:hover {
  background-color: #ddd;
}

.interest-rate-table {
  width: 30%;
}
.interest-rate-table .data-table th,
.data-table td {
  border: 1px solid rgba(217, 217, 217, 0.5);
  padding: 5px 10px;
  text-align: left;
}
/* =========== FOOTER ========================== */
.footer {
  background: #3c3c3e;
}

.footer h1 {
  display: none;
}

.footer-nav {
  display: flex;
  justify-content: space-between;
  padding: 30px 15px 30px 17px;
  max-width: 144rem;
  margin: 0 auto;
}

.links {
  list-style: none;
  font-style: normal;
}

.footer-header {
  color: #fff;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.links-nav {
  padding-top: 7px;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: normal;
}

hr {
  color: #fff;
}

.footer-links {
  color: #fff;
}

.footer-links:hover {
  color: #d49597;
}

.footer-info {
  color: #fff;
  padding: 30px 17px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: normal;
}

.mail {
  font-weight: 100;
  font-size: 1.4rem;
  padding-top: 5px;
  padding-bottom: 5px;
}

.footer-row {
  display: flex;
  flex-direction: row;
}

.footer-hr {
  color: #fff;
  display: block;
}
.footer-column {
  display: none;
}

.footer-bottom {
  background: #3c3c3e;
  color: #fff;
  padding: 20px 15px;
}

.copyright {
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
}
.footer-span {
  color: #fff;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.cookies {
  color: #fff;
  padding-top: 7px;
  font-size: 1rem;
  text-align: center;
  font-weight: 300;
  line-height: 150%;
}
@media screen and (min-width: 300px) and (max-width: 520px) {
  .mid-view h3 {
    font-size: 1.6rem;
    line-height: 120%;
    font-weight: 500;
    padding: 25px 30px;
  }
  .recommend-text {
  font-size: 2rem;

}
}
@media screen and (min-width: 500px) and (max-width: 820px) {
  .overview-header {
    font-size: 2.8rem;
  }
}
@media screen and (min-width: 586px) and (max-width: 820px) {
  .explore-section-container {
    padding: 3rem 20px 10rem;
    max-width: 144rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .explore-section-a {
    background-position: center;
    background-size: cover;
    min-height: 420px;
  }
  .explore-section-b {
    background-position: center;
    background-size: cover;
    min-height: 420px;
  }
  .explore-section-c {
    background-position: inherit;
    background-size: cover;
    min-height: 420px;
  }
  .explore-section-d {
    background-position: center;
    background-size: cover;
    min-height: 420px;
  }
  .explore-section-e {
    background-position: center;
    background-size: cover;
    min-height: 420px;
  }
}

/* NoPageFound.css */
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 100px 0;
}

.error-container h1 {
  font-size: 3.6rem;
}

.error-container p {
  font-size: 2rem;
  color: #888;
}
