@media (min-width: 821px) {
  .reuseable-banner {
    height: calc(100vh - 120px);
    padding: 0px 50px;
  }
  .reuseable-banner-text {
    font-size: 5rem;
  }
  .retail-price-banner {
    background-position: inherit;
  }

  h1 {
    font-size: 5rem;
    font-weight: 700;
  }

  p,
  li {
    font-size: 2rem;
    font-weight: 500;
    line-height: 30px;
  }
  .mobile-menu {
    display: none;
  }
  .show-menu {
    display: none;
  }
  .hamburger {
    display: none;
  }
  .phone-num {
    display: none;
  }
  .phone {
    display: none;
  }
  .header {
    padding: 0;
  }
  .dropdown {
    display: block;
  }
  .dropdown-nav a {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .desktop-nav .dropdown:hover .submenu {
    display: block;
    transition: 05s ease-in-out;
    /* transform: scale(1.1); */
  }
  .submenu {
    display: none;
    position: absolute;
    list-style: none;
    padding: 20px 30px 10px 10px;
    background: #fff;
    transition: 05s ease-in-out;
  }
  .submenu li {
    padding: 5px !important;
  }
  .submenu li:hover {
    transform: scaleX(1.02);
  }
  .not-clickable {
    cursor: not-allowed !important;
    pointer-events: none !important;
  }
  .header-container {
    margin: 0 auto;
    max-width: 144rem;
    display: flex;
    max-width: 144rem;
    padding: 1.5rem 8%;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
  }
  .logo {
    margin-right: 3rem;
  }

  .desktop-nav {
    display: inline-flex;
    list-style: none;
  }

  .desktop-nav a {
    color: #202020;
  }

  .desktop-nav li {
    padding-left: 30px;
    font-size: 1.6rem;
    font-weight: 500;
  }
  .header .desktop-nav a.active {
    color: #a1151a;
  }

  .home-hero-button {
    padding: 10px 10px 10px 32px;
  }
  .hero-button {
    width: 203px;
    height: 60px;
    font-size: 2rem;
    font-weight: 500;
    border-radius: 8px;
    box-shadow: none;
  }

  .hero-container {
    display: flex;
    justify-content: space-between;
    /* height: calc(100vh - 90px); */
    width: 100%;
  }
  .hero-container img {
    height: 598px;
    width: 100%;
    object-fit: cover;
  }
  .banner-container {
    display: block;
    flex: 0 0 50%;
  }
  .hero-content {
    max-width: 70%;
    margin: 0 15% 0 auto;
  }
  .hero-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0px 0px 50px;
    flex: 0 0 50%;
    height: 598px;
    background: #f8f8f8;
  }
  .hero-text h1 {
    max-width: 403px;
    font-size: 50px;
    text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 1px;
    color: #252222;
  }
  .hero-text h4 {
    color: #252222;
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 22px;
    line-height: normal;
    letter-spacing: 0.64px;
    white-space: nowrap;
  }
  .hero-line {
    margin-bottom: 50px;
    width: 285px;
    height: 4px;
  }

  /* ========= OVERVIEW SECTION ============ */

  .overview-container {
    max-width: 144rem;
    margin: 0 auto;
    padding: 5rem 8%;
  }

  .overview {
    margin-bottom: 0;
  }

  .overview-br {
    display: none;
  }

  .overview-header {
    font-size: 3rem;
    line-height: 150%;
    padding: 0rem 0rem 3rem 0rem;
  }

  .overview-text {
    text-align: justify;
    padding: 0rem 0rem 3rem;
  }

  /* ========= MIDVIEW SECTION ============ */

  .mid-view {
    padding: 0 8%;
    max-height: 481px;
    flex-direction: row;
    max-width: 144rem;
    margin: 0 auto;
  }

  .mid-view-text {
    padding: 8% 0;
    width: 50%;
    max-height: 420px;
  }

  .mid-view span {
    font-size: 3.6rem;
  }
  .mid-view h3 {
    font-size: 2.4rem;
    line-height: 150%;
    max-width: 393px;
    font-weight: 700;
    padding: 30px 20px;
  }

  .mid-view img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  /* ========= WHAT WE DO HOME SECTION ============ */

  .section-container {
    padding: 0 8%;
    display: grid;
    gap: 0px 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .home-what-we-do {
    padding: 100px 8% 50px;
  }

  .home-what-we-do h1 {
    font-size: 3rem;
    font-weight: 500;
    padding-bottom: 10px;
  }

  .home-what-we-do p {
    max-width: 1177px;
    font-size: 3rem;
    line-height: 150%;
    font-weight: 700;
    padding-top: 50px;
  }

  .section-a:hover {
    transform: scale(1.02);
  }
  .section-a {
    width: 100%;
    border-radius: 10px;
    transition: 0.3s ease-in-out;
  }

  .section-b:hover {
    transform: scale(1.02);
  }
  .section-b {
    width: 100%;
    border-radius: 10px;
    transition: 0.3s ease-in-out;
  }

  .section-c:hover {
    transform: scale(1.02);
  }
  .section-c {
    width: 100%;
    margin-bottom: 4rem;
    border-radius: 10px;
    transition: 0.3s ease-in-out;
  }

  .section-d:hover {
    transform: scale(1.02);
  }
  .section-d {
    width: 100%;
    margin-bottom: 4rem;
    border-radius: 10px;
    transition: 0.3s ease-in-out;
  }

  .section-text {
    font-size: 3rem;
    font-weight: 700;
    line-height: 42px;
  }

  .count-on-us {
    padding: 7.5rem 0;
  }

  .count-on-us h3 {
    font-size: 3rem;
    padding: 5rem 0rem;
    line-height: 150%;
  }

  .resources {
    padding: 5rem 8%;
    display: flex;
    flex-direction: row;
    gap: 15%;
    align-items: center;
    max-width: 144rem;
    margin: 0 auto;
    justify-content: center;
  }
  .fact-flip {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  /* .flip{
    margin-left: 22rem;
  } */

  .resources img {
    width: 50%;
    margin: 0;
  }

  .resources-container {
    width: 50%;
  }

  .resources h3 {
    font-size: 3rem;
    font-weight: 700;
    padding-top: 0px;
    padding-bottom: 25px;
    line-height: 56px;
    letter-spacing: 1px;
  }

  .resources p {
    max-width: 692px;
    font-size: 2rem;
    line-height: 150%;
    letter-spacing: 1px;
  }
  .resource-link {
    padding-top: 32px;
  }

  /* ============ ABOUT US ============ */
  .about-hero {
    padding: 0;
    background-position: inherit;
  }
  .about-wrapper {
    max-width: 144rem;
    margin: 0 auto;
    padding: 23rem 8%;
  }

  #mission-container {
    padding: 0rem 2rem;
  }
  .mission-image-seg1 {
    width: 100%;
    max-width: 102.4rem;
    border-radius: 15px;
    height: 436px;
  }
  .mission-image-seg2 {
    width: 100%;
    max-width: 102.4rem;
    border-radius: 15px;
    height: 436px;
  }
  .mission-image-seg3 {
    width: 100%;
    max-width: 102.4rem;
    border-radius: 15px;
    height: 436px;
  }
  .mission-overlap-container {
    max-width: 144rem;
    padding: 3rem 0rem;
    border: none;
    margin: 0 auto;
    margin-bottom: 6rem;
  }
  .mission-overlap-container h2 {
    text-align: center;
    font-size: 3rem;
    padding-bottom: 3rem;
  }
  .mission-overlap-container p {
    margin: 0 auto;
    max-width: 1024px;
  }
  .what-we-do {
    padding: 13rem 0;
  }
  .what-we-do-text {
    font-size: 3.5rem;
    letter-spacing: 4px;
  }
  .founder-wrapper {
    width: 100%;
  }

  .founder-container h2 {
    font-size: 3rem;
  }
  .founder-content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 50px;
  }
  .founder-content img {
    max-width: 420px;
  }

  /* ============ RECOMMENDATIONS ============ */
  .recommend-hero {
    padding: 0;
    background-position: inherit;
    margin-bottom: 10rem;
  }
  /* .recommendation-container {
    position: relative;
  } */
  .recommed-wrapper {
    max-width: 144rem;
    margin: 0 auto;
  }
  .recommend-hero h1 {
    padding: 23rem 8%;
    max-width: 144rem;
    margin: 0 auto;
  }
  .recommendations {
     padding: 0 0rem 5rem 8%;
    /* padding: 0 13.8rem 10.5rem; */
    position: relative;
  }
  .recommend-text {
    font-size: 3rem;
    max-width: 144rem;
    margin: 0 auto;
    padding: 0rem 8% 4rem;
  }

  .recommend-card {
    padding: 50px;
    max-width: 699px;
    min-height: 298px;
    margin-bottom: 15rem;
    gap: 50px;
  }
  .recommendation-wrapper {
    background: url(./assets/recommendation-bg.png);
    background-repeat: no-repeat;
        background-size: contain;
        position: relative;
  }
  .recommendations-card-wrapper {
    max-width: 1200px;
    height: 4428px;
    position: relative;
    max-height: 4000px;
  }
  .recommend-desc {
    font-size: 1.8rem;
  }
  .recommend-name {
    font-size: 3rem;
  }
  .recommend-comp {
    font-size: 3rem;
  }
 

 

  /* ======== OUR SERVICES ========== */

  .service-hero {
    padding: 0;
    background-position: inherit;
  }

  .service-hero h1 {
    padding: 24rem 8%;
  }
  .service-banner {
    background-image: url(../src/assets/services/laptop.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 13rem 0;
  }

  .service-banner h2 {
    font-size: 3rem;
    font-weight: 800;
  }

  .service-writeup {
    font-weight: 500;
    line-height: 30px;
    max-width: 144rem;
    margin: 0 auto;
    padding: 5rem 8% 2rem;
  }

  .service-card-wrapper {
    padding: 0;
  }

  .service-page-h3 {
    padding: 2rem 0;
  }

  .service-page-h4 {
    padding: 0rem 0rem 1.5rem 0rem;
    font-size: 2rem;
  }

  .card-container {
    max-width: 144rem;
    padding: 0 8% 10rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  .service-card {
    border-radius: 8px;
    border: 2px solid transparent;
    box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .service-image {
    max-width: 465px;
    width: 100%;
  }
  .service-card-content {
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .service-card-title {
    color: #a1151a;
    font-size: 2rem;
    font-weight: 700;
  }
  .service-card-description {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 150%;
  }
  .service-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }

  .service-card a {
    transition: 0.3s ease-in-out;
  }
  .service-card a:hover {
    transform: scale(1.03);
  }
  .service-btn span {
    padding: 1rem;
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 0px 0px 4px;
    background: #d9d9d9;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .service-icon {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 100%;
    border-radius: 0px 4px 4px 0px;
    background: #a1151a;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 600;
  }

  /* ============ FACTSHEET ============ */
  .factsheet-hero h1 {
    padding: 24rem 8%;
  }
  .factsheet-hero {
    margin-bottom: 5rem;
    padding: 0;
  }
  .factsheet-cards-container {
    padding: 0 8% 7rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  .resource-card {
    display: flex;
    width: 100%;
    border-radius: 15px;
    background: #f8f8f8;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    min-height: 405px;
    padding: 45px 25px 35px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
  }

  /* ============ RESOURCES PAGE ============ */
  /* .resource-hero h1{
        padding: 23rem 8%;
  }
  .resource-hero{
    padding: 0;
    margin-bottom: 5rem;
  } */
  .resource-text {
    max-width: 144rem;
    margin: 0 auto;
    padding: 5rem 8%;
    font-weight: 500;
    line-height: 30px;
  }

  .resource-hero {
    height: 75vh;
    padding: 0;
    margin-bottom: 5rem;
  }
  .resource-hero-container {
    height: 100%;
  }
  .resource-hero h1 {
    padding: 10rem 8%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  /* ===========ACCSEND======================== */
  .accsend-container {
    padding: 0 8%;
    max-width: 144rem;
    margin: 0 auto;
  }
  .accsend-overview {
    max-width: 819px;
    font-size: 3rem;
    padding: 6rem 0rem 2rem;
  }
  .resource-accsend-text {
    padding: 5rem 0;
  }

  /* ============ BUSINESS START UP PAGE ============ */
  .business-hero h1 {
    padding: 24rem 8%;
  }
  .business-hero {
    padding: 0;
  }
  .business-start-text {
    text-align: center;
    padding: 0 8%;
  }
  .business-start-line {
    margin: 3rem 0 5rem;
  }
  /* ======== COMPLIANCE  PAGE ======= */
  .compliance-hero h1 {
    padding: 24rem 8%;
  }
  .compliance-hero {
    padding: 0;
  }
  /* ======== CONSULTANCY PAGE ======= */
  .consult-hero {
    padding: 0;
    background-position: inherit;
  }

  .consult-hero h1 {
    color: white;
    margin-bottom: 2.4rem;
    max-width: 144rem;
    margin: 0 auto;
    padding: 24rem 8%;
  }
  .consultancy img {
    padding-bottom: 3rem;
  }
  /* ======== TAXATION PAGE ======= */
  .taxation-hero h1 {
    padding: 24rem 8%;
  }
  .taxation-hero {
    padding: 0;
  }

  .tax-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 144rem;
    margin: 0 auto;
  }
  .tax-wrapper-reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .taxation-image-seg1 {
    width: 100%;
    background-position: inherit;
    height: 500px;
  }
  .taxation-image-seg2 {
    max-width: 994px;
    height: 500px;
  }
  .taxation-image-seg3 {
    width: 100%;
    height: 570px;
  }
  .taxation-image-seg4 {
    width: 100%;
    height: 550px;
  }

  .taxation-image-seg5 {
    width: 100%;
    height: 849px;
  }
  .tax-container-modifier {
    width: 100%;
    margin-left: calc(1vw - 195px);
    margin-top: calc(1vw - -34px);
    max-width: 567px;
    max-height: 445px;
    padding: 5rem 4rem;
    line-height: 30px;
    z-index: 100;
  }
  .tax-mid-modifier {
    width: 100%;
    margin-right: calc(1vw - 208px);
    margin-top: calc(1vw - -4px);
    max-width: 567px;
    height: 345px;
    padding: 6rem 4rem 3rem;
    line-height: 30px;
    z-index: 100;
  }
  .tax-hmrc-modifier {
    width: 100%;
    margin-right: calc(1vw - 165px);
    margin-top: calc(1vw - 10px);
    max-width: 567px;
    max-height: 445px;
    padding: 5rem 4rem;
    line-height: 30px;
    z-index: 100;
  }
  .tax-overlay {
    width: 100%;
    max-width: 581px;
    max-height: 755px;
    margin-left: calc(1vw - 200px);
    margin-top: calc(1vw - 10px);
    padding: 6.3rem 4rem;
    font-size: 2.5rem;
    line-height: 30px;
  }
  .taxation-overlap-container {
    width: 100%;
    max-width: 581px;
    max-height: 455px;
    margin-left: calc(1vw - 200px);
    margin-top: calc(1vw - 10px);
    padding: 6.3rem 4rem 6.3rem 4rem;
    font-size: 2.5rem;
    line-height: 30px;
  }
  .taxcal-box {
    padding: 0 8%;
    max-width: 144rem;
    margin: 0 auto;
  }
  .taxcal-table-container {
    margin: 0;
  }
  .tax-cal-input {
    margin: 0;
  }
  /* ============ support-service PAGE ============ */

  .support-service-hero h1 {
    padding: 24rem 8%;
  }
  .support-service-hero {
    padding: 0;
  }

  .support-header {
    font-size: 3rem;
    padding: 5rem 0 3.5rem 0;
    font-weight: 400;
  }

  .compliance-service-text {
    font-weight: 700;
    padding-top: 3.2rem;
  }

  .require-info-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    max-width: 144rem;
    margin: 0 auto 3rem auto;
  }

  .explore-image-seg1 {
    width: 100%;
    max-width: 534px;
    height: 450px;
  }

  .explore-overlap-container {
    width: 623px;
    height: 402px;
    margin: auto 0;
    padding: 7.8rem 3rem;
  }
  .explore-overlap-container h2 {
    padding-bottom: 4rem;
    font-size: 3rem;
  }
  .explore-overlap-container p {
    max-width: 533px;
    font-size: 2rem;
    line-height: 32px;
  }
  .explore-container {
    padding-left: 30px;
    background: #f8f8f8;
  }

  .explore-container h3 {
    color: #a1151a;
    font-size: 2.8rem;
    font-weight: 700;
    padding-bottom: 40px;
    line-height: 56px;
    letter-spacing: 1px;
  }

  .explore-container p {
    font-size: 2.8rem;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 1px;
    padding-bottom: 47px;
  }

  .explore {
    padding: 0;
  }

  .explore-header {
    color: #a1151a;
    font-size: 3rem;
    max-width: 144rem;
    margin: 0 auto;
    padding: 3rem 8%;
  }
  .explore-section-container {
    padding: 3rem 8% 10rem;
    max-width: 144rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .explore-section-a {
    width: 100%;
    height: 350.204px;
    border-radius: 10px;
  }

  .explore-section-b {
    width: 100%;
    height: 350.204px;
    border-radius: 10px;
  }

  .explore-section-c {
    width: 100%;
    height: 350.204px;
    border-radius: 10px;
  }

  .explore-section-d {
    width: 100%;
    height: 350.204px;
    border-radius: 10px;
  }
  .explore-section-e {
    width: 100%;
    height: 350.204px;
    border-radius: 10px;
  }

  .explore-section-text {
    font-size: 2.4rem;
    font-weight: 600;
    text-align: center;
  }
  .require-info-container {
    margin-top: 0rem;
  }

  /* ======== INVESTMENT PAGES ========== */

  .invest-hero h1 {
    padding: 24rem 8%;
  }
  .invest-hero {
    padding: 0;
  }

  /* ======== RESOURCE PAGES ======== */

  .custom-input {
    padding: 2.1rem 8% 5rem;
    max-width: 144rem;
    margin: 0 auto;
  }

  .top-input {
    max-width: 1251px;
    height: 50px;
    border: 1px solid #d9d9d9;
    background: rgba(217, 217, 217, 0.5);
    color: #a1151a;
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: 0.4px;
    padding: 13px 0 12px 10px;
  }

  .bottom-input {
    border: 2px solid #d9d9d9;
    max-width: 1251px;
    padding: 13px 0 12px 10px;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 0.4px;
  }

  .resource-overview-text {
    padding: 6rem 8% 0rem;
    font-size: 3rem;
    line-height: 48px;
    margin: 0 auto;
    max-width: 144rem;
  }

  .personal-text {
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3rem;
    letter-spacing: 0.04rem;
    padding: 6rem 0rem 0rem 9.1rem;
  }

  .resource-identifier {
    padding: 3rem 8% 0;
    font-size: 2rem;
    font-weight: 600 !important;
  }

  /* ============ GET IN TOUCH ============ */
  .get-in-touch {
    background-image: url(assets/home/banner.png);
  }
  .get-in-touch-wrapper {
    max-width: 144rem;
    margin: 0 auto;
    display: flex;
    padding: 64px 8%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .get-in-touch-header {
    font-size: 3rem;
    font-weight: 700;
    padding: 0px;
  }

  /* ======== CONTACT US ========== */
  .contact-hero {
    background-position: inherit;
    padding: 0;
  }

  .contact-hero h1 {
    font-size: 5rem;
    padding: 24rem 8%;
  }

  .contact-info {
    padding-left: 0px;
  }

  .contact-container {
    max-width: 144rem;
    margin: auto;
    padding: 50px 8% 33px;
  }

  .contact-text {
    font-size: 2rem;
    font-weight: 400;
    line-height: 35px;
    padding-left: 30px;
  }

  .contact-email {
    font-size: 2rem;
    font-weight: 400;
    line-height: 30px;
    padding-left: 30px;
  }

  .question-text {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 2px;
    padding: 124px 0 0 0;
  }

  .contact-form {
    padding-left: 2.4rem;
  }

  .contact-form h1 {
    font-size: 3.2rem;
    font-weight: 600;
    padding-bottom: 4rem;
  }

  .form-label {
    font-size: 2rem;
    padding-bottom: 1rem;
  }

  .form-name {
    display: flex;
    flex-direction: row;
  }

  input {
    width: 100%;
    height: 60px;
    margin-bottom: 33px;
  }

  .firstName {
    margin-right: 30px;
  }

  .firstName input {
    min-width: 345px;
  }

  .lastName input {
    min-width: 345px;
  }
  textarea {
    height: 374px;
  }

  input::placeholder {
    padding-top: 2.1rem;
    font-size: 1rem;
  }

  textarea::placeholder {
    padding-left: 9px;
    padding-top: 9px;
    font-size: 1.4rem;
  }

  .contact-btn {
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .map-outer {
    max-width: 144rem;
    padding: 0px 74px 50px;
    margin: 0 auto;
  }

  .map_canvas {
    width: 100%;
    /* height: 100vh; */
    background: #fff;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .map_iframe {
    width: 971px;
    max-width: 100%;
    height: 70vh;
    border: 0;
  }

  /* ============ FAQS =========================== */

  .faqs-container {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    max-width: 144rem;
    margin: 0 auto;
    padding: 50px 8% 41px;
  }

  .faqs-container h1 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 48px;
    max-width: 285px;
  }
  .faq-btn {
    display: block;
  }
  .faqs-col {
    flex-direction: column !important;
  }
  .faqs-full {
    width: 100% !important;
  }

  .transparent-link {
    text-decoration: none;
    border-radius: 15px;
    border: 1px solid #a1151a;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 20px;
    background-color: transparent;
    transition: all 0.3s ease;
    color: #a1151a;
    font-size: 2rem;
    font-weight: 700;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    height: 60px;

    &:hover {
      background-color: #a1151a;
      color: white;
    }
  }

  .faq-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mobile-btn {
    display: none !important;
  }
  .accordion-wrapper {
    width: 70%;
  }
  .accordion-item {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background: #fff;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
    padding: 20px 30px;
    min-height: 100px;
  }
  .accordion-que {
    display: flex;
    gap: 50px;
    align-items: center;
    cursor: pointer;
  }
  /* .accordion-que h2{
  max-width: 560px;
 } */
  .accordion-ans {
    display: none;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  }
  .accordion-ans .show {
    display: block;
    height: auto;
    max-height: 100%;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
  }
  .show p {
    margin-top: 30px;
    padding-left: 80px;
    font-size: 1.6rem;
  }

  /* ===========RetailPriceTable================ */
  .retail-price-wrapper {
    padding: 40px 97px 100px;
  }
  .retail-price-container {
    max-width: 144rem;
    margin: 0 auto;
  }
  .retail-price-container p {
    color: #a1151a;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 23px;
  }
  .retail-price-table {
    max-width: 80vw;
    margin: 0 auto;
  }
  .data-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 1.2rem;
  }

  .data-table th,
  .data-table td {
    padding: 5px 10px;
  }

  /* ===========FOOTER SECTION=========================== */

  .footer-hr {
    display: none;
  }
  .footer-nav {
    display: flex;
    justify-content: space-between;
    padding: 45px 8% 87px;
  }

  .footer-info {
    color: #fff;
    padding: 0;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: normal;
  }
  .footer-nav h1 {
    display: block;
    justify-content: flex-start;
    align-self: flex-start;
    color: white;
    font-size: 3rem;
    font-weight: 600;
  }

  .footer-column {
    display: block;
  }
  .footer-row {
    display: none;
  }

  .footer-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 25px 8% 15px;
  }
  .links-nav {
    padding-top: 10px;
    font-size: 1.6rem;
  }
  .footer-info p {
    font-size: 1.6rem;
    line-height: 150%;
    font-weight: 600;
  }
  .mail {
    font-weight: 100 !important;
    font-size: 1.4rem !important;
    padding: 10px 0 !important;
  }
  .copyright {
    font-size: 1.2rem;
    font-weight: 300;
  }
  .footer-span {
    font-size: 1rem;
  }

  .cookies {
    padding-top: 0px;
    font-size: 1.2rem;
  }
}

@media (min-width: 1024px) {
  .factsheet-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px 30px;
  }
  .hero-text {
    height: 531px;
    flex: 0 0 43%;
  }
  .banner-container {
    display: block;
    flex: 0 0 57%;
  }
  .hero-content {
    max-width: 70%;
    margin: 0 10% 0 auto;
  }
  .mid-view h3 {
    font-size: 3rem;
    line-height: 64px;
    max-width: 393px;
    font-weight: 700;
  }
}

@media (min-width: 1200px) {
  .taxation-image-seg2 {
    background-position: inherit;
  }
  .card-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .explore-section-container {
    grid-template-columns: repeat(5, 1fr);
  }
  .founder-container {
    max-width: 144rem;
    margin: 0 auto;
    padding: 10rem 8%;
  }
  .recommendations{
    max-width: 144rem;
    margin: 0 auto;
    padding: 3rem 8% 10rem;
  }
    .redfox-text h1{
    color: rgba(120, 116, 116, 0.19);
      font-size: 110px;
      font-weight: 900;
      letter-spacing: 3px;
      display: block;
      white-space: nowrap;
      transform: rotate(-90deg);
      position: absolute;
       top: 20%;
    right: -20%;
  }
  .bottom-svg{
     display: block;
  }
.rec-eclispe-svg{
  width:100%;
  z-index: -1;
  position: absolute;
  top:400%;
  max-width: 550px;
  right:20%;
}
.rec-circle-svg{
  width:100%;
  z-index: -1;
  position: absolute;
  top:450%;
  max-width: 850px;
  left:20%;
}

}
@media (min-width: 1440px) {
  .section-container {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 821px) and (max-width: 1000px) {
  .consult-hero {
    background-position: center;
  }
  .founder-content {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 1500px) {
  .footer-nav {
    padding: 45px 8% 50px;
    max-width: 144rem;
    margin: 0 auto;
  }
  .footer-bottom {
    padding: 25px 8% 15px;
    max-width: 144rem;
    margin: 0 auto;
  }
  .section-container {
    padding: 0 8%;
    max-width: 144rem;
    margin: 0 auto;
  }
  .hero-content {
    max-width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
  }

  .about-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .about-hero {
    height: 75vh;
  }
  .about-wrapper {
    height: 100%;
    display: flex;
  }
  .resource-hero {
    height: 75vh;
  }
  .resource-hero-container {
    height: 100%;
  }
  .resource-hero h1 {
    padding: 24rem 8%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .service-hero {
    height: 75vh;
  }
  .service-hero h1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 27rem 8%;
  }
  .consult-hero {
    height: 75vh;
  }
  .consult-hero h1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 27rem 8%;
  }
  .invest-hero {
    height: 75vh;
    background-position: inherit;
  }
  .invest-hero h1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 27rem 8%;
  }
  .recommend-hero {
    height: 75vh;
}
  .recommend-hero h1{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 27rem 8%;
}
  .contact-hero {
    height: 75vh;
  }
  .contact-hero h1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 27rem 8%;
  }
}
