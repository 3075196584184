
@import url("https://fonts.googleapis.com/css2?family=Comme:wght@100;300;400;500;700;800;900&display=swap");

:root {
  font-size: 62.5%;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Comme", sans-serif;
}

h2{
  font-size: 2.5rem;
  font-weight: 600;
}

button{
  cursor: pointer;
  
}

a{
  text-decoration: none;
  cursor: pointer;
  color: inherit
}

a:hover {
  color: #a11511;
}

.resource-link:hover {
  color: #D49597;
}


@import url('./mobile.css');
@import url('./desktop.css');